export default {
    data() {
        return {
            isHoverBtn:false,

            outSearchForm: {}, // 暂存

            detail: {
                cols: {},
                show: false,
                data: {},
                full: false,
            },

            //成分多级跳转
            componentList: [
                {
                    url: '/through-component',     //跳转连接
                    text: '按成分查药品',           //按钮名称
                    icon: 'el-icon-set-up',
                    params: [                        //跳转传参参数
                        { txt: 'code' }
                    ]
                },
                {
                    url: '/ch-medicinal-price',     //跳转连接
                    text: '中药材详情',           //按钮名称
                    icon: 'el-icon-set-up',
                    params: [                        //跳转传参参数
                        { comCode: 'chineseMedicinalCode' },
                        { comName: 'chineseMedicinalName' },
                    ]
                },
            ],
            //成分多级跳转
            indicationList: [
                {
                    url: '/through-indication',     //跳转连接
                    text: '按疾病查药品',           //按钮名称
                    icon: 'el-icon-office-building',
                    params: [                        //跳转传参参数
                        { code: 'code' },
                        { name: 'name' },
                    ]
                },
            ],

            drawerForm: {
                title:'',
                drawer: false,
                direction: 'rtl',
                list: []
            },
        }
    },
    methods: {
        clickContext(row,title) {
            this.drawerForm.title = title
            this.drawerForm.list = row
            this.drawerForm.drawer = true
        },
        goRegister(data) {
            const params = {}
            switch (data.type) {
                case 0:
                case 2:
                    params.comName = data.comName
                    params.comCode = data.comCode
                    break;
                case 1:
                    params.compName = data.compName
                    params.compCode = data.compCode
                    break;
                default:
                    break;
            }
            this.goPage('/store/flow_development_review_progress', params)
        },
        goBid(data) {
            const params = {
                comName: data.comName,
                compName: data.compName,
                unifySpec: data.unifySpec,
                pack: data.pack,
                conversionRatio: data.conversionRatio,
            }
            this.goPage('/store/mainf_drug_winning_informations', params)
        },
        goNation(data) {
            console.log('参数',data);
            const params = {
                comName: data.comName,
                compName: data.compName,
            }
            this.goPage('/store/dr_national_centralized_procurement', params)
        },
        goChina(data) {
            const params = this.newParams(data)
            this.goPage('/store/dr_domestic_drugs', params)
        },
        newParams(data) {
            const params = {}
            switch (data.type) {
                case 0:
                    params.comName = data.comName
                    params.comCode = data.comCode
                    break;
                case 1:
                    params.compName = data.compName
                    params.compCode = data.compCode
                    break;
                case 2:
                    params.comName = data.comName
                    params.comCode = data.comCode
                    params.compName = data.compName
                    params.compCode = data.compCode
                    break;
                default:
                    break;
            }
            return params
        },
        goPage(url, obj) {
            const { href } = this.$router.resolve({
                path: url,
                query: obj
            });
            window.open(href, "_blank");
        },
        //筛选
        sort(param) {
            this.sortParam = {}
            if (param) {
                this.sortParam = param
            }
            this.init();
        },
        //便捷时间筛选
        searchTime(val) {
            let fleg = true
            if (val && val.length != 0) {
                for (let index = 0; index < val.length; index++) {
                    console.log('22220', val[index], val[index].value, this.conditionList);
                    if (!val[index].value) {
                        fleg = false
                    }
                    if (this.conditionList && this.conditionList.length != 0) {
                        this.conditionList.forEach((item, i) => {
                            if (item.name === this.screenName) {
                                this.conditionList.splice(i, 1)
                            }
                        })
                    }
                }
            } else {
                fleg = false
            }
            this.conditionList = !fleg ? this.conditionList : this.conditionList.concat(val)
            this.init()
        },
        //单独筛选
        outSearch() {
            let arr = []
            let kesList = Object.keys(this.outSearchForm)
            if (kesList && kesList.length != 0) {
                kesList.forEach(item => {
                    if (this.outSearchForm[item]) {
                        arr.push({
                            name: item,
                            op: 'like',
                            value: this.outSearchForm[item],
                        })
                    }
                    if (this.conditionList && this.conditionList.length != 0) {
                        this.conditionList.forEach((orr, j) => {
                            if (orr.name === item) {
                                this.conditionList.splice(j, 1);
                            }
                        })
                    }
                })
            }
            this.conditionList = this.conditionList.concat(arr)
            this.init()
        },
        //点击详情
        detailShow(row) {
            this.detail.cols = this.cols.map((item, index) => {
                item.viewName = item.value
                item.fieldName = item.key
                item.id = index
                item.detailView = '是'
                if (item.key === 'comName') {
                    item.isComTotal = true
                }
                return item
            })
            if (row.type === 2) {
                this.detail.cols.splice(1, 0, {
                    id: 'compName1', fieldName: 'compName',
                    viewName: '企业名称', width: 200, sort: true, align: 'tl', detailView: '是', isComTotal: true
                })
            }else if(row.type === 1){
                this.detail.cols[0].fieldName = 'compName'
            }
            this.detail.show = true
            this.detail.data = row
        },
        listComputed(list) {
            return list.map(item => {
                item = this.newLink(item)
                return item
            })
        },
        newLink(orr){
            const item = this.$help.deepClone(orr)
            if (item.componentLink) {
                const arr = JSON.parse(item.componentLink)
                arr.sort((orr1, orr2) => {
                    return orr2.name.length - orr1.name.length
                })
                arr.forEach((j, i) => {
                    item.component = item.component.replace(j.name, `##{${i}}##`)
                })
                item.component = this.setObj(item.component.split('##'), arr)
            }
            if (item.indicationLink) {
                const arr = JSON.parse(item.indicationLink)
                arr.sort((orr1, orr2) => {
                    return orr2.name.length - orr1.name.length
                })
                arr.forEach((j, i) => {
                    item.indication = item.indication.replace(j.commonName || j.name, `##{${i}}##`)
                })
                item.indication = this.setObj(item.indication.split('##'), arr)
            }
            return item
        },
        setObj(obj, list) {
            return obj.map(orr => {
                if (orr.indexOf("{") != -1) {
                    const t = orr.replace("{", "").replace("}", "")
                    orr = list[t]
                }
                return orr
            })
        },

        showComponent(e) {
            this.goComponentOrIndication(e, 'through-component')
        },
        showIndication(e) {
            this.goComponentOrIndication(e, 'through-indication')
        },
        goComponentOrIndication(e, path) {
            const select = window.getSelection()
            if (select.type === 'Range') {
                const val = select.toString()
                if (val) {
                    this.searchTip.x = e.pageX + 30
                    this.searchTip.y = e.pageY - 15
                    this.searchTip.show = true
                    this.searchTip.txt = val
                    this.searchTip.path = path
                }
            }
        },
        goComponentOrIndicationHandle(e) {
            this.goComponentOrIndication(e.e, e.path)
        },
        // goSearchThrough() {
        //     const { href } = this.$router.resolve({
        //         path: `/${this.searchTip.path}?txt=${this.searchTip.txt}`,
        //     });
        //     window.open(href, "_blank");
        // },
    }
}